<template>
  <component :is="component" :class="[$style.headline, cssClass]" v-on="$listeners">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'VueHeadline',
  props: {
    level: {
      type: String,
      required: true,
    },
    weightLevel: {
      type: String,
    },
    appearanceLevel: {
      type: String,
    },
    native: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
    },
  },
  computed: {
    component() {
      let component = `h${this.level}`;

      if (this.native === false) {
        component = 'div';
      }

      return component;
    },
    cssClass() {
      const classes = [
        this.$style[`text-font-weight-${this.weightLevel}`],
        this.$style[`text-${this.color}`],
      ];

      classes.push(
        this.appearanceLevel
          ? this.$style[`h${this.appearanceLevel}`]
          : this.$style[`h${this.level}`],
      );
      return classes;
      // return this.appearanceLevel
      //   ? this.$style[`h${this.appearanceLevel}`]
      //   : this.$style[`h${this.level}`];
    },
  },
};
</script>

<style lang="scss" module>
@import '~@/styles/styles';

.headline {
  font-family: $headline-font-family;
  margin: 0;
  width: 100%;
}

@each $variation, $values in $headline-variations {
  #{$variation} {
    font-size: map-get($values, 'font-size');
    font-weight: map-get($values, 'font-weight');
    letter-spacing: map-get($values, 'letter-spacing');
    line-height: map-get($values, 'line-height');
  }

  .#{$variation} {
    font-size: map-get($values, 'font-size');
    font-weight: map-get($values, 'font-weight');
    letter-spacing: map-get($values, 'letter-spacing');
    line-height: map-get($values, 'line-height');
  }
}
@each $variation, $weight in $palette-font-weights {
  .text-#{$variation} {
    font-weight: $weight;
  }
}

@each $variation, $color in $palette-colors {
  .text-#{$variation} {
    color: $color;
  }
}
</style>
